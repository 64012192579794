import React, { useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import vhCheck from 'vh-check';
import colors from '../components/_colors.scss';

const Home = styled.main`
	position: relative;
	width: 100%;
	background-size: cover;
	background-image: ${props => `url(${props.background})`};
	background-repeat: no-repeat;
	background-position: 20%;
	height: 100vh;
	/* If you need to support browser without CSS var support (<= IE11) */
	height: calc(100vh - var(--vh-offset, 0px));
	/* enable vh fix */

	@media (min-width: 768px) {
		background-position: 10%;
	}

	@media (min-width: 1024px) {
		background-position: left;
	}
`;

const MissionStatement = styled.h1`
	position: absolute;
	bottom: 75px;
	left: 20px;
	font-size: 2em;
	max-width: 15ch;
	color: #f9fdf7;

	@media (min-width: 1024px) {
		bottom: 100px;
		left: 50px;
		font-size: 4em;
		max-width: 23ch;
	}
`;

const IndexPage = () => {
	const homeData = useStaticQuery(graphql`
		{
			allSanityHome {
				nodes {
					missionStatement
					heroImage {
						asset {
							url
							fluid {
								base64
								aspectRatio
								src
								srcSet
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
				}
			}
		}
	`);

	const { missionStatement, heroImage } = homeData?.allSanityHome?.nodes[0];

	useEffect(() => {
		const test = vhCheck();
		return () => {};
	}, [0]);

	return (
		<React.Fragment>
			<SEO title='Home | Marquise Stillwell' />
			<Home background={heroImage.asset.url}>
				{missionStatement && <MissionStatement>{missionStatement}</MissionStatement>}
			</Home>
		</React.Fragment>
	);
};

export default IndexPage;
